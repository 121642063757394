<div class="row" style="min-height:100%; overflow-y: auto;">
	<div class="d-flex align-items-start ms-5 me-3">
		<h5 translate>recommendations.title</h5>
	</div>
	<div id="carouselControls" class="carousel slide carousel-fade w-100" data-ride="carousel">
		<div class="carousel-inner text-center">
			<div [ngClass]="i === 0 ? 'carousel-item active': 'carousel-item'"
				*ngFor="let recommendation of recommendations; let i = index" data-interval="5000">
				<img NgOptimizedImage class="imgAuthors" src="{{recommendation.authorImage}}" alt="author-image" loading="lazy">
				<small>{{recommendation.state}}</small>
				<p>{{recommendation.text}}</p>
				<p class="blockquote-footer">{{recommendation.author}}</p>
			</div>
		</div>

		<a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
			<span class="carousel-control-prev-icon" aria-hidden="true"></span>
			<span class="sr-only">Previous</span>
		</a>
		<a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
			<span class="carousel-control-next-icon" aria-hidden="true"></span>
			<span class="sr-only">Next</span>
		</a>

	</div>
</div>